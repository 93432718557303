// <!-- TYPES -->
import { HierarchyTreeNode } from './HierarchyTreeNode';
import { LocationHierarchyResource } from '@/models/locations/LocationHierarchy';
/** @typedef {import('@/models/locations/Location').LocationResource} LocationResource */

/**
 * Representation of an input element.
 */
export class HierarchyInput {
    // #region <!-- PROTECTED MEMBERS -->

    /** @type {HierarchyTreeNode} Hierarchy tree node. */
    _treeNode = null;

    /** @type {import('@formkit/core').FormKitNode} FormKit node. */
    _formkitNode = null;

    /** @type {HTMLInputElement} Underlying HTML element, if it's been loaded. */
    _input = null;

    /** @type {'hierarchy'|'text'|'location'} Type of input. */
    _type = null;

    /** @type {String} Stored value of the input. */
    _value = '';

    /** @type {Set<'updating'|'clearing'|'initialized'>} Status flag tracker. */
    _status = new Set();

    /** @type {Set<String>} */
    _errors = new Set();

    // #endregion

    // #region <!-- CONSTRUCTOR -->

    /**
     * Construct input with its corresponding tree node reference.
     *
     * @param {HierarchyTreeNode} node Hierarchy tree node.
     * @param {'hierarchy'|'location'|'text'} type Input type.
     */
    constructor(node, type) {
        if (!node) {
            throw new TypeError(
                `Missing required hierarchy tree node reference.`
            );
        }
        if (!type) {
            throw new TypeError(`Missing required input type.`);
        }
        this._treeNode = node;
        this._type = type;
    }

    // #endregion

    // #region <!-- PROPERTIES (DATA) -->

    /**
     * Readonly accessor for the corresponding tree node reference.
     */
    get treeNode() {
        return this._treeNode;
    }

    /**
     * Readonly accessor to the FormKit node.
     */
    get formNode() {
        return this._formkitNode;
    }

    /**
     * Readonly accessor to the hierarchy tree context.
     */
    get context() {
        return this.treeNode.context;
    }

    /**
     * Readonly accessor to the input type.
     *
     * @returns {String}
     */
    get type() {
        switch (this._type) {
            case 'hierarchy':
            case 'location':
                return 'select';
            case 'text':
            default:
                return 'text';
        }
    }

    /**
     * Get memoized reference to the underlying input element. If `null`, it has not yet been loaded.
     */
    get input() {
        if (!this._input) {
            try {
                console.groupCollapsed(
                    `[get::input::element] @ ${new Date().toLocaleString()}`
                );
                const element = /** @type {HTMLInputElement} */ (
                    document.getElementById(this.id)
                );
                console.dir({ input: element });
                this._input = element;
            } catch (err) {
                console.error(err);
                return null;
            } finally {
                console.groupEnd();
            }
        }
        return this._input;
    }

    /**
     * Readonly accessor to the value.
     */
    get value() {
        return this._value ?? '';
    }

    /**
     * Readonly accessor to the namespaced identifier for this input element.
     */
    get id() {
        return [this.treeNode.id, this.type].join('-');
    }

    /**
     * Readonly accessor to the namespaced data name for this input element.
     */
    get name() {
        return [this.treeNode.id, this.type, 'value'].join('-');
    }

    /**
     * Readonly accessor to the input depth.
     */
    get depth() {
        return this.treeNode.depth;
    }

    /**
     * Readonly accessor to the inferred account tree level label or default label, if none are defined.
     */
    get label() {
        const depth = this.depth;
        const label = this.context.getLabel(depth) ?? `Hierarchy [${depth}]`;
        return label;
    }

    /**
     * Readonly accessor to the input element's help text. Override in an extending class.
     */
    get help() {
        return `Help text.`;
    }

    /**
     * Readonly accessor to the input element's placeholder text. Override in an extending class.
     */
    get placeholder() {
        return `Placeholder text.`;
    }

    /**
     * Determine if the input is currently active. Override to provide custom enabled behavior.
     */
    get active() {
        return true;
    }

    /**
     * Determine if the input is currently inactive. Override `active()` to provide custom behavior.
     */
    get inactive() {
        return !this.active;
    }

    /**
     * Determine if the input is editable. Override to provide custom behavior.
     */
    get disabled() {
        return false;
    }

    /**
     * Determine if the selector value is invalid.
        Input value is invalid when:
        - The value is null or empty.
     */
    get invalid() {
        // Input value is invalid when:
        // - The value is null or empty.
        const isNullOrEmpty = this._value === null || this._value === '';
        return isNullOrEmpty;
    }

    /**
     * Determine if the input is ignored.
     */
    get ignore() {
        return false;
    }

    /**
     * Determine if the input value is preserved when deleted.
     */
    get preserve() {
        return true;
    }

    /**
     * Get the errors array.
     *
     * @returns {String[]}
     */
    get errors() {
        return [...this._errors];
    }

    // #endregion

    // #region <!-- PROPERTIES (VALIDATORS) -->

    /**
     * Is this a root input?
     */
    get isRoot() {
        return this.treeNode.isRoot;
    }

    /**
     * Is this a leaf input?
     */
    get isLeaf() {
        return this.treeNode.isLeaf;
    }

    /**
     * Does this input have a parent?
     */
    get hasParent() {
        return this.treeNode.hasParent;
    }

    /**
     * Does this input have a child?
     */
    get hasChild() {
        return this.treeNode.hasChild;
    }

    /**
     * Does the specified status exist?
     */
    get isInitialized() {
        return this._status.has('initialized');
    }

    /**
     * Enable or disable the specified status.
     * @param {Boolean} value
     */
    set isInitialized(value) {
        if (value === false) {
            this._status.delete('initialized');
        } else {
            this._status.add('initialized');
        }
    }

    /**
     * Does the specified status exist?
     */
    get isUpdating() {
        return this._status.has('updating');
    }

    /**
     * Enable or disable the specified status.
     * @param {Boolean} value
     */
    set isUpdating(value) {
        if (value === false) {
            this._status.delete('updating');
        } else {
            this._status.add('updating');
        }
    }

    /**
     * Does the specified status exist?
     */
    get isClearing() {
        return this._status.has('clearing');
    }

    /**
     * Enable or disable the specified status.
     * @param {Boolean} value
     */
    set isClearing(value) {
        if (value === false) {
            this._status.delete('clearing');
        } else {
            this._status.add('clearing');
        }
    }

    /**
     * Determine if the FormKit node is present.
     */
    get hasFormKitNode() {
        return !!this._formkitNode;
    }

    /**
     * Determine if the input element is present.
     */
    get hasInputElement() {
        return !!this.input;
    }

    // #endregion

    // #region <!-- METHODS (MUTATORS) -->

    /**
     * Set the value stored by this input and invoke the value changed event if the value has changed.
     *
     * @param {String} value Value to set.
     */
    setValue(value) {
        if (!this.active) {
            console.warn(`Input is not active.`);
            return;
        }
        const previous = this._value;
        const next = String(value ?? '');
        this._value = next;
        console.dir({ previous, currrent: this._value });
        if (previous !== next) {
            this.onValueChanged();
        } else {
            console.warn(`No change in value...`);
        }
    }

    // #endregion

    // #region <!-- METHODS (ACCESSORS) -->

    /**
     * Is it the matching input type?
     *
     * @param {'location'|'hierarchy'|'text'} type
     * @returns {Boolean}
     */
    isType(type) {
        return this._type === type;
    }

    /**
     * Section schema CSS.
     */
    outerClass() {
        const base = '$reset group w-full px-2';
        const modifier =
            this.context.isRefreshing || this.isUpdating || this.isClearing
                ? 'animate-pulse'
                : '';
        return [base, modifier].join(' ').trimEnd();
    }

    /**
     * Section schema CSS.
     */
    wrapperClass() {
        return '';
    }

    /**
     * Section schema CSS.
     */
    innerClass() {
        const base = '$reset w-full';
        const modifier = this.disabled ? 'cursor-not-allowed' : '';
        return [base, modifier].join(' ').trimEnd();
    }

    /**
     * Section schema CSS.
     */
    inputClass() {
        const base = '$reset w-full px-3 text-sm rounded-lg';
        const modifier =
            this.context.isRefreshing ||
            this.isUpdating ||
            this.isClearing ||
            this.disabled
                ? 'cursor-not-allowed'
                : '';
        return [base, modifier].join(' ').trimEnd();
    }

    /**
     * Section schema CSS.
     */
    labelClass() {
        const base = 'mt-2';
        const whenDisabled =
            'text-gray-500 group-hover:text-gray-600 cursor-not-allowed';
        const whenEnabled = 'group-hover:text-blue-600';
        return [base, this.disabled ? whenDisabled : whenEnabled]
            .join(' ')
            .trimEnd();
    }

    /**
     * Section schema CSS.
     */
    helpClass() {
        return '';
    }

    /**
     * Class rules object used by FormKit to modify section schema styles.
     */
    classes() {
        return {
            outer: this.outerClass(),
            wrapper: this.wrapperClass(),
            inner: this.innerClass(),
            input: this.inputClass(),
            label: this.labelClass(),
            help: this.helpClass(),
        };
    }

    // #endregion

    // #region <!-- METHODS (ACTIONS) -->

    /**
     * Run everytime the input is made active.
     */
    activate() {
        console.log(
            `[activate::${this.type}] @ ${new Date().toLocaleString()}`
        );
    }

    /**
     * Run everytime the input is made inactive.
     */
    deactivate() {
        console.log(
            `[deactivate::${this.type}] @ ${new Date().toLocaleString()}`
        );
    }

    /**
     * Invoke the init event for this input.
     *
     * @param {Object} params Parameters passed to the hierarchy input instance.
     * @param {String} [params.value] Optional initial value. If `null`, initializes to empty String.
     */
    init(params) {
        try {
            console.groupCollapsed(
                `[init::input::< ${this.id} >] @ ${new Date().toLocaleString()}`
            );
            // Assign initial value.
            this._value = params?.value ?? '';
            // Assign the initialization flag.
            this.isInitialized = true;
        } finally {
            console.groupEnd();
        }
    }

    /**
     * Invoke the clear event for this input.
     */
    clear() {
        try {
            console.groupCollapsed(
                `[clear::input::< ${
                    this.id
                } >] @ ${new Date().toLocaleString()}`
            );
            // Begin clearing.
            this.isClearing = true;
            // Assign the cleared value.
            this._value = '';
            // Invoke the onValueCleared() event.
            this.onValueCleared();
        } finally {
            this.isClearing = false;
            console.groupEnd();
        }
    }

    // #endregion

    // #region <!-- METHODS (EVENTS) -->

    /**
     * Register initialized node.
     *
     * @param {import('@/components/sidebar/hooks/useDateRangeFilter').FormKitNode} node Initialized node.
     */
    onFormKitNode(node) {
        try {
            console.groupCollapsed(
                `[init::FormKitNode::< ${
                    this.id
                } >] @ ${new Date().toLocaleString()}`
            );
            if (!!node) {
                console.dir({ node });
                this._formkitNode = node;
            }
        } finally {
            console.groupEnd();
        }
    }

    /**
     * Handle the `@input` event on a `FormKit` input.
     *
     * @param {any & { __init?: Boolean }} value Value passed by the form kit event.
     * @param {import('@/components/sidebar/hooks/useDateRangeFilter').FormKitNode} node Node raising the input event.
     */
    onFormKitInput(value, node) {
        try {
            console.groupCollapsed(
                `[input::FormKitNode::< ${
                    this.id
                } >] @ ${new Date().toLocaleString()}`
            );

            // Update node registration.
            if (!!node) {
                console.log(`Updating FormKitNode "${node.name}" reference.`);
                this.onFormKitNode(node);
            }

            // If value initialization is occurring, do nothing.
            if (value?.__init === true) {
                console.warn(`Initializing form input...`);
                return;
            }

            // Handle the form input if not the initialization run.
            this.onValueInput(String(value));
        } finally {
            console.dir({ current: this._value });
            console.groupEnd();
        }
    }

    /**
     * Handle the value that has been input.
     *
     * @param {String} value Value to handle.
     */
    onValueInput(value) {
        try {
            console.groupCollapsed(
                `[input::${this.type}::< ${
                    this.id
                } >] @ ${new Date().toLocaleString()}`
            );
            // Assign updating flag.
            this.isUpdating = true;
            // Update the current value.
            this.setValue(value);
        } finally {
            this.isUpdating = false;
            console.groupEnd();
        }
    }

    /**
     * Invoked when the value is changed. Clears value if it is invalid.
     */
    onValueChanged() {
        if (this.invalid) {
            console.warn(`Clearing invalid input value...`);
            this.clear();
        }
        console.dir({ current: this._value, valid: !this.invalid });
    }

    /**
     * Invoked when the value is cleared. Clears the formkit node and input elements.
     */
    onValueCleared() {
        // Clear the formkit value, if it exists.
        if (this.hasFormKitNode) {
            this.formNode._value = '';
        }
        // Clear the input element, if it exists.
        if (this.hasInputElement) {
            this.input.value = '';
        }
    }

    // #endregion
}

/**
 * Representation of a `<select>` element.
 */
export class HierarchySelector extends HierarchyInput {
    // #region <!-- PROTECTED MEMBERS -->

    /**
     * @type {LocationHierarchyResource[]} Options available to this input, prior to formatting.
     */
    _options = [];

    // #endregion

    // #region <!-- CONSTRUCTOR -->

    /**
     * Construct input with its corresponding tree node reference.
     *
     * @param {HierarchyTreeNode} node Hierarchy tree node.
     */
    constructor(node) {
        super(node, 'hierarchy');
    }

    // #endregion

    // #region <!-- PROPERTIES (DATA) -->

    /**
     * Get the typed {@link HTMLSelectElement} reference.
     */
    get selector() {
        return !this.input
            ? null
            : // @ts-ignore
              /** @type {HTMLSelectElement} */ (this.input);
    }

    /**
     * Get the `<option>` compatible options array.
     *
     * @returns {{ label?: String, value: String }[]}
     */
    get options() {
        return this._options.map(this.convertResourceToOption);
    }

    /**
     * Determine if the selector is the active input.
     */
    get active() {
        const preferSelector = this.treeNode.preferSelectMode;
        const enforceTextbox = this.treeNode.forceTextMode;
        return preferSelector && !enforceTextbox;
    }

    /**
     * FormKit element property.
     *
     * @returns {String}
     */
    get help() {
        return `Select a new ${this.label}. Use the plus button to create a new ${this.label} instead.`;
    }

    /**
     * FormKit element property.
     *
     * @returns {String}
     */
    get placeholder() {
        return `No ${this.label} selected...`;
    }

    /**
     * Determine if the selector input is disabled.
     */
    get disabled() {
        if (this.isRoot) {
            // If root, never disable the selector.
            return false;
        } else {
            // If not root, disable if:
            // - ...this selector input options array is empty.
            // - ...parent selector input does not have a selected value.
            // - ...parent node is in textbox mode.
            const hasNoOptions = !this.hasOptions;
            const parent = this.treeNode.parent;
            const parentHasInvalidValue = !parent || parent?.selector?.invalid;
            const parentUsingTextbox = !!parent && parent?.textbox?.active;
            return parentUsingTextbox || hasNoOptions || parentHasInvalidValue;
        }
    }

    /**
     * Determine if the selector value is invalid.
        Input value is invalid when:
        - Selector value is null or empty.
        - Selector options array is empty.
        - Selector value is not included in array of option values.
     */
    get invalid() {
        const isNullOrEmpty = super.invalid || !this.hasSelectedValue;
        const hasNoOptions = !this.hasOptions;
        const ids = hasNoOptions
            ? []
            : this.options.map((option) => option.value);
        const isMissing =
            isNullOrEmpty || hasNoOptions || !ids.includes(this._value);
        return isMissing;
    }

    // #endregion

    // #region <!-- PROPERTIES (VALIDATORS) -->

    /**
     * Determine if the value field is non-null and non-empty.
     */
    get hasSelectedValue() {
        return this._value !== null && this._value !== '';
    }

    /**
     * Determine if the options array is empty.
     */
    get hasOptions() {
        return this.options && this.options.length > 0;
    }

    // #endregion

    // #region <!-- METHODS (HELPERS) -->

    /**
     * Create an option item from the provided resource.
     *
     * @param {LocationHierarchyResource} resource Resource to get option from.
     */
    convertResourceToOption(resource) {
        return {
            label: String(resource?.name),
            value: String(resource?.id),
        };
    }

    /**
     * Create a resource from the provided option item.
     *
     * @param {{ label?: String, value: String }} option Option to get resource from.
     */
    convertOptionToResource(option) {
        return !!option && !!option.value
            ? this.context.getHierarchy(option.value)
            : null;
    }

    // #endregion

    // #region <!-- METHODS (ACCESSORS) -->

    /**
     * Get the selected hierarchy referenced by the stored value.
     *
     * @returns {LocationHierarchyResource}
     */
    getSelectedHierarchy() {
        const selected = this.invalid
            ? null
            : this.context.getHierarchy(this.value);
        return selected;
    }

    // #endregion

    // #region <!-- METHODS (MUTATORS) -->

    /**
     * Set the value stored by this input using a resource.
     *
     * @param {LocationHierarchyResource} resource Selected resource.
     */
    setSelectedHierarchy(resource) {
        const value = resource ? String(resource.id) : null;
        this.onValueInput(value);
    }

    /**
     * Set options for the current input.
     *
     * @param {Object} params
     * @param {LocationHierarchyResource[]} [params.resources] Resources used to set the options array directly.
     * @param {{ label?: String, value: String }[]} [params.options] Options used to set the options array indirectly.
     * @returns {this}
     */
    setOptionsUsing({ resources, options }) {
        if (Array.isArray(resources)) {
            // If resources array is provided, set options using resources.
            return this.setOptions(resources);
        } else if (Array.isArray(options)) {
            // If options array is provided, map into resources and set that.
            this.setOptions(options.map(this.convertOptionToResource));
        } else {
            // Set the options array to an empty array.
            this.setOptions([]);
        }
    }

    /**
     * Set the options for this input using the passed resources.
     *
     * @param {LocationHierarchyResource[]} resources
     * @returns {this}
     */
    setOptions(resources) {
        this._options = resources.slice(0);
        this.onOptionsChanged();
        return this;
    }

    // #endregion

    // #region <!-- METHODS (ACTIONS) -->

    /**
     * Invoke the init event for this input.
     *
     * @param {Object} params Parameters passed to the hierarchy input instance.
     * @param {String} params.value Initial value.
     * @param {LocationHierarchyResource[]} params.options Initial options.
     */
    init(params) {
        try {
            console.groupCollapsed(
                `[init::input::< ${this.id} >] @ ${new Date().toLocaleString()}`
            );

            // Assign initial options.
            this._options = params?.options ?? [];

            // Assign initial value, if it's included in the options.
            const exists =
                params?.value !== null &&
                params?.value !== '' &&
                this._options.some((h) => String(h.id) === params?.value);
            this._value = exists ? params?.value : '';

            // Assign the initialization flag.
            this.isInitialized = true;
        } finally {
            console.groupEnd();
        }
    }

    /**
     * When the textbox is activated, clear the input.
     */
    activate() {
        super.activate();
    }

    /**
     * When the textbox is deactivated, clear the input.
     */
    deactivate() {
        super.deactivate();
        this.clear();
    }

    // #endregion

    // #region <!-- METHODS (EVENT) -->

    /**
     * When the selector value is changed:
     * - Get the children for the selected hierarchy.
     *   - If selected hierarchy cannot be found, clear the current value.
     * - Assign retrieved children as options for the immediate child, if a child is present.
     */
    onValueChanged() {
        // Clear value, formkit node, and HTML element if:
        // - Value is null or empty.
        // - No options are present.
        // - Selected value is NOT in the collection of known ids.
        super.onValueChanged();

        // Get the selected hierarchy.
        const selected = this.context.getHierarchy(this._value);
        if (!selected) {
            // If selected hierarchy doesn't exist, clear the value.
            console.warn(
                `No matching hierarchy resource found that corresponds to the current selection (id = [${this._value}]).`
            );
            this.clear();
        }

        // If this is NOT a leaf node and the immediate selector value has changed...
        // - Update the child selector options. Empty array is acceptable.
        //   - This will also handle clearing the child selector value.
        if (this.hasChild) {
            const child = this.treeNode.child;

            if (child.selector !== null) {
                const children = selected?.children ?? [];
                console.log(
                    `Updating child input with ${children.length} hierarchy option(s).`
                );
                child.selector.setOptions(children);
            }

            if (child.locationSelector !== null) {
                const children = selected?.locations ?? [];
                console.log(
                    `Updating child input with ${children.length} location option(s).`
                );
                child.locationSelector.setOptions(children);
            }
        }
    }

    /**
     * When the selector value is cleared:
     * - Run the normal onValueCleared event.
     * - Execute the clear event in the child's selector input.
     */
    onValueCleared() {
        // Run the normal onValueCleared event.
        super.onValueCleared();

        // Clear the selector element's selectedIndex as well.
        if (this.hasInputElement) {
            // Reference: https://usefulangle.com/post/83/html-select-common-operations-with-javascript
            this.selector.value = '';
        }

        // If this is NOT a leaf node and the immediate selector value has changed...
        // - Clear the child selector options using an empty array.
        //   - This will also handle clearing the child selector value.
        if (this.hasChild) {
            const child = this.treeNode.child;

            if (child.selector !== null) {
                console.log(`Updating child input with empty options array.`);
                child.selector.setOptions([]);
            }

            if (child.locationSelector !== null) {
                console.log(`Updating child input with empty options array.`);
                child.locationSelector.setOptions([]);
            }
        }
    }

    /**
     * When the selector options are changed:
     * - Clear the current value.
     * - Update options in children, if it is present.
     */
    onOptionsChanged() {
        // Clear the selector value.
        // - onValueCleared() handles updating children, if present.
        this.clear();
    }

    // #endregion
}

/**
 * Representation of a leaf Location `<select>` element.
 */
export class LocationSelector extends HierarchyInput {
    // #region <!-- PROTECTED MEMBERS -->

    /**
     * @type {LocationResource[]} Options available to this input, prior to formatting.
     */
    _options = [];

    // #endregion

    // #region <!-- CONSTRUCTOR -->

    /**
     * Construct input with its corresponding tree node reference.
     *
     * @param {HierarchyTreeNode} node Hierarchy tree node.
     */
    constructor(node) {
        super(node, 'location');
    }

    // #endregion

    // #region <!-- PROPERTIES (DATA) -->

    /**
     * Get the typed {@link HTMLSelectElement} reference.
     */
    get selector() {
        return !this.input
            ? null
            : // @ts-ignore
              /** @type {HTMLSelectElement} */ (this.input);
    }

    /**
     * Get the text value for the selector location.
     */
    get text() {
        const location = this.getSelectedLocation();
        return !!location ? location.name : '';
    }

    /**
     * Get the `<option>` compatible options array.
     *
     * @returns {{ label?: String, value: String }[]}
     */
    get options() {
        return this._options.map(this.convertResourceToOption);
    }

    /**
     * Determine if the selector is the active input.
     */
    get active() {
        return this._options.length > 0;
    }

    /**
     * Readonly accessor to the inferred account tree level label or default label, if none are defined.
     */
    get label() {
        return `Location`;
    }

    /**
     * FormKit element property.
     *
     * @returns {String}
     */
    get help() {
        return `Select a new ${this.label}.`;
    }

    /**
     * FormKit element property.
     *
     * @returns {String}
     */
    get placeholder() {
        return `No ${this.label} selected...`;
    }

    /**
     * Determine if the selector input is disabled.
     */
    get disabled() {
        if (this.isRoot) {
            // If root, never disable the selector.
            return false;
        } else {
            // If not root, disable if:
            // - ...this selector input options array is empty.
            // - ...parent selector input does not have a selected value.
            // - ...parent node is in textbox mode.
            const hasNoOptions = !this.hasOptions;
            const parent = this.treeNode.parent;
            const parentHasInvalidValue = !parent || parent?.selector?.invalid;
            return hasNoOptions || parentHasInvalidValue;
        }
    }

    /**
     * Determine if the selector value is invalid.
        Input value is invalid when:
        - Selector value is null or empty.
        - Selector options array is empty.
        - Selector value is not included in array of option values.
     */
    get invalid() {
        const isNullOrEmpty = super.invalid || !this.hasSelectedValue;
        const hasNoOptions = !this.hasOptions;
        const ids = hasNoOptions
            ? []
            : this.options.map((option) => option.value);
        const isMissing =
            isNullOrEmpty || hasNoOptions || !ids.includes(this._value);
        return isMissing;
    }

    // #endregion

    // #region <!-- PROPERTIES (VALIDATORS) -->

    /**
     * Determine if the value field is non-null and non-empty.
     */
    get hasSelectedValue() {
        return this._value !== null && this._value !== '';
    }

    /**
     * Determine if the options array is empty.
     */
    get hasOptions() {
        return this.options && this.options.length > 0;
    }

    /**
     * Location nodes are terminal leaf nodes.
     */
    get hasChild() {
        return false;
    }

    // #endregion

    // #region <!-- METHODS (HELPERS) -->

    /**
     * Create an option item from the provided resource.
     *
     * @param {LocationResource} resource Resource to get option from.
     */
    convertResourceToOption(resource) {
        return {
            label: String(resource?.name),
            value: String(resource?.id),
        };
    }

    /**
     * Create a resource from the provided option item.
     *
     * @param {{ label?: String, value: String }} option Option to get resource from.
     */
    convertOptionToResource(option) {
        const parent = this.getParentHierarchy();
        const locations = this.context.getLocations(parent?.id);
        const location = locations.find(
            (l) => String(l.id) === String(option.value)
        );
        return !!option && !!option.value ? location : null;
    }

    // #endregion

    // #region <!-- METHODS (ACCESSORS) -->

    /**
     * Get the selected location hierarchy referenced by the parent node.
     *
     * @returns {LocationHierarchyResource}
     */
    getParentHierarchy() {
        const id = this.hasParent ? this.treeNode.parent.selector.value : null;
        return this.context.getHierarchy(id);
    }

    /**
     * Get the selected location referenced by the stored value.
     *
     * @returns {LocationResource}
     */
    getSelectedLocation() {
        if (!this.invalid) {
            const hierarchy = this.getParentHierarchy();
            const locations = !!hierarchy
                ? hierarchy.locations
                : this.context.getLocations(hierarchy?.id);
            const selected = locations.find(
                (location) => String(location?.id) === String(this.value)
            );
            return selected;
        }
        return null;
    }

    // #endregion

    // #region <!-- METHODS (MUTATORS) -->

    /**
     * Set the value stored by this input using a resource.
     *
     * @param {LocationResource} resource Selected resource.
     */
    setSelectedLocation(resource) {
        const value = resource ? String(resource.id) : null;
        this.onValueInput(value);
    }

    /**
     * Set options for the current input.
     *
     * @param {Object} params
     * @param {LocationResource[]} [params.resources] Resources used to set the options array directly.
     * @param {{ label?: String, value: String }[]} [params.options] Options used to set the options array indirectly.
     * @returns {this}
     */
    setOptionsUsing({ resources, options }) {
        if (Array.isArray(resources)) {
            // If resources array is provided, set options using resources.
            return this.setOptions(resources);
        } else if (Array.isArray(options)) {
            // If options array is provided, map into resources and set that.
            this.setOptions(options.map(this.convertOptionToResource));
        } else {
            // Set the options array to an empty array.
            this.setOptions([]);
        }
    }

    /**
     * Set the options for this input using the passed resources.
     *
     * @param {LocationResource[]} resources
     * @returns {this}
     */
    setOptions(resources) {
        this._options = resources.slice(0);
        this.onOptionsChanged();
        return this;
    }

    // #endregion

    // #region <!-- METHODS (ACTIONS) -->

    /**
     * Invoke the init event for this input.
     *
     * @param {Object} params Parameters passed to the hierarchy input instance.
     * @param {String} params.value Initial value.
     * @param {LocationResource[]} params.options Initial options.
     */
    init(params) {
        try {
            console.groupCollapsed(
                `[init::input::< ${this.id} >] @ ${new Date().toLocaleString()}`
            );

            // Assign initial options.
            this._options = params?.options ?? [];

            // Assign initial value, if it's included in the options.
            const exists =
                params?.value !== null &&
                params?.value !== '' &&
                this._options.some((l) => String(l.id) === params?.value);

            this._value = exists ? params?.value : '';

            // Assign the initialization flag.
            this.isInitialized = true;
        } finally {
            console.groupEnd();
        }
    }

    /**
     * When the textbox is activated, clear the input.
     */
    activate() {
        super.activate();
    }

    /**
     * When the textbox is deactivated, clear the input.
     */
    deactivate() {
        super.deactivate();
        this.clear();
    }

    // #endregion

    // #region <!-- METHODS (EVENT) -->

    /**
     * When the selector value is changed:
     * - Get the children for the selected hierarchy.
     *   - If selected hierarchy cannot be found, clear the current value.
     * - Assign retrieved children as options for the immediate child, if a child is present.
     */
    onValueChanged() {
        // Clear value, formkit node, and HTML element if:
        // - Value is null or empty.
        // - No options are present.
        // - Selected value is NOT in the collection of known ids.
        super.onValueChanged();

        // Get the selected location.
        const selected = this.getSelectedLocation();
        if (!selected) {
            // If selected location doesn't exist, clear the value.
            console.warn(
                `No matching location resource found that corresponds to the current selection (id = [${this._value}]).`
            );
            this.clear();
        }

        // Location nodes are terminal and cannot have children, so no propogation occurs.
    }

    /**
     * When the selector value is cleared:
     * - Run the normal onValueCleared event.
     * - Execute the clear event in the child's selector input.
     */
    onValueCleared() {
        // Run the normal onValueCleared event.
        super.onValueCleared();

        // Clear the selector element's selectedIndex as well.
        if (this.hasInputElement) {
            // Reference: https://usefulangle.com/post/83/html-select-common-operations-with-javascript
            this.selector.value = '';
        }

        // Location nodes are terminal and cannot have children, so no propogation occurs.
    }

    /**
     * When the selector options are changed:
     * - Clear the current value.
     * - Update options in children, if it is present.
     */
    onOptionsChanged() {
        // Clear the selector value.
        // - onValueCleared() handles updating children, if present.
        this.clear();
    }

    // #endregion
}

/**
 * Representation of a `<input type="text">` element.
 */
export class HierarchyTextbox extends HierarchyInput {
    // #region <!-- CONSTRUCTOR -->

    /**
     * Construct input with its corresponding tree node reference.
     *
     * @param {HierarchyTreeNode} node Hierarchy tree node.
     */
    constructor(node) {
        super(node, 'text');
    }

    // #endregion

    // #region <!-- PROPERTIES (DATA) -->

    /**
     * Get the typed {@link HTMLInputElement} reference.
     */
    get textbox() {
        return this.input ?? null;
    }

    /**
     * Determine if the textbox is the active input.
     */
    get active() {
        const preferTextMode = this.treeNode.preferTextMode;
        const enforceTextbox = this.treeNode.forceTextMode;
        return enforceTextbox || preferTextMode;
    }

    /**
     * FormKit element property.
     *
     * @returns {String}
     */
    get help() {
        return `Create new ${this.label}.`;
    }

    /**
     * FormKit element property.
     *
     * @returns {String}
     */
    get placeholder() {
        return `Create new ${this.label} here...`;
    }

    /**
     * Determine if the selector input is disabled.
     */
    get disabled() {
        if (this.isRoot) {
            // If root, never disable the selector.
            return false;
        } else {
            const parent = this.treeNode.parent;
            // If not root and...
            // ...if parent is in selector mode:
            if (parent?.selector?.active) {
                // Disable textbox if no selector value is selected.
                const parentHasInvalidValue = parent?.selector?.invalid;
                return parentHasInvalidValue;
            }
            // ...if parent is in textbox mode:
            if (parent?.textbox?.active) {
                // Disable textbox if previous textbox is empty.
                const parentHasEmptyTextbox = parent?.textbox?.invalid;
                return parentHasEmptyTextbox;
            }
            // No need to disable if conditions not already met.
            return false;
        }
    }

    // #endregion

    // #region <!-- METHODS (HELPERS) -->

    /**
     * Create a resource from the provided name.
     *
     * @returns {{ id: String, name: String, parent: String, child: String }}
     */
    getOutput() {
        const parent = this.isRoot ? null : this.treeNode.parent;
        const hasParent = !!parent;
        const parentUsingSelector = hasParent && parent?.selector?.active;
        const parentUsingTextbox = hasParent && parent?.textbox?.active;
        const parentSelectorValue = parentUsingSelector
            ? parent?.selector?.getSelectedHierarchy()
            : null;
        const parentTextboxValue = parentUsingTextbox
            ? parent?.textbox?.value
            : null;
        const parentValue = parentUsingSelector
            ? parentSelectorValue?.name
            : parentTextboxValue;
        const child = this.hasChild ? null : this.treeNode.child;
        const childHasTextboxValue = !!child && child?.textbox?.invalid;
        const childValue = childHasTextboxValue
            ? child?.textbox?.getOutput()?.name
            : null;

        // Create resource.
        const resource = {
            id: `<New Hierarchy>`,
            name: this._value,
            parent: parentValue ?? '<No Parent>',
            child: childValue ?? '<No Child>',
        };
        return resource;
    }

    // #endregion

    // #region <!-- METHODS (ACTIONS) -->

    /**
     * When the textbox is activated, clear the input.
     */
    activate() {
        super.activate();
        this.clear();
    }

    /**
     * When the textbox is deactivated, clear the input.
     */
    deactivate() {
        super.deactivate();
        this.clear();
    }

    // #endregion
}

// EXPOSE
export default {
    HierarchyInput,
    HierarchySelector,
    HierarchyTextbox,
};
